
@media all and (max-width: 1495px)  {

	
}
@media all and (max-width: 1370px)  {

	#SliderDown{
		display: none;
	}


	.sliderSectionInner .part2{
		padding-left: 0px !important;
	}
	.leftSection{
		display: none !important;
	}
	.SliderPage{
		overflow-y: auto !important;
	}
	.overlay{
		display: none !important;
	}
	.ms-tableCell,
	.leftSection,
	.ms-left,
	#SplitSlider{
	    float: left !important;
	    width: 100% !important;
	    clear: both !important;
	    position: relative !important;
	    height: auto !important;
	    top: auto !important;
	    div{ 
	    	float: left !important;
		    width: 100% !important;
		    clear: both !important;
		    position: relative !important;
		    height: auto !important;
		    top: auto !important;
	    }
	}
	body, html {
		overflow-y: auto !important;
	}
	.SliderPage{
		#header{
			min-height: 49px;
			background-color: $themeColor;
			@include box-shadow(0px 0px 20px 0px rgba(0,0,0,0.3));
		}
		.homebtn{
			top: 7px;
			img{
				width: 150px;
			}
		}
		#Menu{
			margin-top: 0px;
		}

		#MenuIcon{
			top: -11px;
		}
	}

	.AlternateHeader #header {
	    min-height: 49px;
	    background-color: #fff !important;
	}


	#header{
		min-height: 49px;
		background-color: $themeColor;
		@include box-shadow(0px 0px 20px 0px rgba(0,0,0,0.3));
	}
	.homebtn{
	left: 20px; 
		top: 7px;
		img{
			width: 150px;
		}
	}
	#Menu{
		margin-top: 0px;
	}

	#MenuIcon, #MobileSearch{
		top: -11px;
	}
	.week-indicator{
		top: 1.5em;
	}

}
@media all and (max-width: 1350px)  {
	.homebtn img {
	    width: 180px;
	}
	.homebtn {
    	left: 20px;
    }
}
@media all and (max-width: 1100px)  {
	.clearMid{
		clear: both;
		width: 100%;
		padding: 0px !important; 
	}
}
@media all and (min-width: 1601px)  {
	#MobileNavigation{
		display: none !important;
	}
}
@media all and (max-width: 1600px)  {
	.btnLeft, .btnRight {
   	 	font-size: 1.4vw;
		min-height: 50px;
    }
	#MenuIcon, #MobileSearch{
		display: block !important;
	}
	#Menu{
		display: none !important;
	}

	.bannerTitleNews{
		width: 100%;
	}
	.col-five.NewsItem{
		width: 25%;
	}
}


@media all and (max-width: 1024px)  {
	.QuickLinks,
	.bannerText{
		display: none !important;
	}
	.MobileQuickLinks{
		display: block;
	}
	.flex-direction-nav{
		display: none;
	}
	.overlay2,
	#SliderBg {
		display: none;
	}
	#home2{
		background-color: #bf221f;
		margin-top: 0px; 
		min-height: 0px;
		.homeTitle{
			margin-top: 0px;
		}
		.readMore{
			background-color: #AF1E1C;
		}
	}
	
	 .bg.parallax{
	 	margin-top: 0px !important;
	 }
}



@media all and (max-width: 960px)  {
	.col-five.NewsItem{
		width: 33.33%;
	}
}



@media all and (max-width: 788px)  {
	.col-five.NewsItem{
		width: 50%;
		.NewsItemInner{
		padding-bottom: 100%;
		}
	}
}


@media all and (max-width: 550px)  {
	.col-five.NewsItem{
		width: 100%;
	}
}

@media all and (max-width: 1165px)  {
	#home2{
		padding-left: 10%;
		padding-right: 10%;
	}
}
@media all and (max-width: 1150px)  {
	#home5{
		.calendarSection{
			width: 100%;
			clear: both;
		}
		.NewsSection{
			position: relative;
			left: auto;
			top: auto;
			height: auto;
			min-height: auto;
			width: 100%;
			float: left;
			clear: both !important;
			@include transform(translate(0px, 0px));
		}
	}
	#home5 {
	    margin-bottom: 30px;
	}
	.NewsSection .tar{
		padding-top: 20px;
		text-align: center !important;
	}
}
@media all and (max-width: 1000px)  {
	
	#home2 .prev {
	    left: 7px;
	}
	#home2 .next {
	    right: 7px;
	}
}
@media all and (max-width: 900px)  {
	#home3 .prev {
	    left: -35px;
	}
	#home3 .next {
	    right: -35px;
	}
}
@media all and (max-width: 788px)  {
	#SuccessStoriesContainerOuter {
		font-size: 0.8em !important;
	}

	.quoteBox{
		display: none !important;
	}

	.NewsItem{
		width: 100%;
		clear: !important;
		padding: 10px !important;
	}
	.NewsItemInner {
	    padding-bottom: 60%;
	}


	.homeContent{
		.flex{
			display: block !important;
		}
		.col-4{
			min-height: 300px;
		}
		.col-4,
		.col-8{
			width: 100%;
			clear: both !important;
		}
	}

	.btnLeft, .btnRight {
    	font-size: 1em;
    }
    .btnContainer{
    position: relative;
    transform: translate(0px, 0px);
    float: left;
    	.col-6{
	    	clear: both;
	    	width: 100% !important;
	    	padding: 0px !important;
    	}
    }
    .pageTitle{
    	font-size: 2em !important;
    }
    .homeTitle {
	    font-size: 0.6em;
	    text-align: center !important;
	    *{

	    text-align: center !important;
	    }
	}

	#home5{
		.pageTitle{
			text-align: center;
		}
	}

	#home3 {
	    padding: 10px 10% 0px 10%;
	}

	#home3 .homeTitle {
		position: relative;
		font-size: 0.4em;
		padding-bottom: 0px !important;
		left: auto !important;
	}
	#TweetSliderContainer {
		padding-top: 0px !important;
	}
    .homeContent1{
    	padding-left: 20px;
    	padding-right: 20px;
    }
    .homeImage{
    	width: 100%;
    	height: 100%;
    }

    .homeContent{
    padding-left: 0px !important;
   		margin-top: 0px !important;
    }

    .bannerText {
    	font-size: 2em;
		text-align: center;
		left: 50%;
		top: 50%;
		@include transform($center);
    }

	.btnLeft{
		svg{
		position: absolute;
			top: 50%;
			left: auto;
			right: 10px;
			height: 90%;
			@include transform(translateY(-50%)  scale(1.4));
		}
	}

}


@media all and (max-width: 1030px)  {
	*{
		background-attachment: initial !important;
		-webkit-background-attachment: initial !important;
	}
}

/* Calendar Responsive */
@media all and (max-width: 600px)  {
	.calWidgetContainer,
	.MonthContainer{
		width: 100%;
	}
	.MonthDropDown{
		@include transform(translateX(-140px));
	}
	.MonthDropDownContainer {
   		 width: 74%;
	}
	.MonthDropDownIconContainer,
	.MonthDropDownContainer{
		width: 100%;
		float: left;
		margin-left: 0px !important;
	}
	/*.calPrev {
	    left: 10px;
	}
	.calNext {
	    right: 10px;
	}*/
	.MonthDropDownContainer{
		width: 140px;
   	 	position: absolute;
   	 	left: 50%;
   	 	@include transform(translateX(-50%));
	}
}
/* Calendar Responsive */

@media all and (max-width: 600px)  {
	.QuickLinks{
		display: none !important;
	}

	.sliderSectionInner .homeTitle{
	    font-size: 0.38em !important;
	}

}
@media all and (max-width: 550px)  {
	#home3 .topAngle2, #home3 .topAngle{
		height: 100px !important;
	}

	#home4 {
	      height: 489px;
	}
	.bannerText {
		text-align: center;
		left: 50%;
		top: 50%;
		@include transform($center);
    	font-size: 1.3em;
    }
}




/*** Newsletters Page Responsive ***/
@media all and (max-width: 726px)  {
	.NewsletterItem{
		width: 50% !important;
	}
}
@media all and (max-width: 500px)  {
    .NewsletterItem{
		width: 100% !important;
	}
	.sliderInnerTweet {
	    padding: 20px;
	    color: #fff;
	    background-color: #bf221f;
	    font-size: 0.8em;
	}
}
/*** Newsletters Page Responsive ***/

/*** Staff Responsive ***/
@media all and (max-width: 520px)  {
	.StaffPage .staffProfile .profilePic {
	    margin: 0 0px 30px 0 !important;
	    float: left;
	    width: 100%;
	    clear: both;
	    display: block;
	}
}
/*** Staff Responsive ***/
/*** Gallery Responsive ***/

@media all and (max-width: 800px)  {
    .galleryImage{
        width: 50% !important;
        padding-bottom: 50% !important;
    }
}
@media all and (max-width: 768px)  {
	.grid-item, .grid-sizer { 
		width: 50% !important; 
	}
}
@media all and (max-width: 500px)  {
    .galleryImage{
        width: 100% !important;
        padding-bottom: 100% !important;
    }
    .sliderInnerTweet{
    	.col-6,
    	.col-3{
    		width: 100%;
    		clear: both;
    		text-align: center;
    	}
    }
    .bannerTitle{
    	text-align: center;
    }
}

@media all and (max-width: 450px)  {
	.grid-item, .grid-sizer { 
		width: 100% !important; 
	}
}
/*** Gallery Responsive ***/






