*{
	font-family: $font-stack;
	color: $textDark;
	text-decoration: none !important;
	outline: none !important;
	-webkit-font-smoothing: antialiased;
}

body{
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #fff;
}

img{
	max-width: 100%;
}


button,
input[type=submit]{
   -webkit-appearance: none;
   -moz-appearance:    none;
   appearance:         none;
}

img{
	height: auto;
}


/** General Site layouts **/

/*** Page ***/
.PageResults,
.InnerPage{
	.banner{
		height: 66vh;
	}
}

.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.CalendarFilter{
	padding: 10px;
}

/* .CalendarFilter{
	span{
		display: inline-block;
		padding: 5px 0px;
		margin: 0px 5px;
		cursor: pointer;
	}
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
	display: block;
} */
.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
	max-width: 500px;
	margin: 0px !important;
	padding: 0px !important;
}

#allEvents ul li{
	margin-bottom: 10px;
	background-color: $themeColor;
	float: left;
    width: 100%;
    clear: both;
    @include transition(background 0.35s);
    display: block;
}






.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #333 !important;
	}
	.url{
		padding: 10px;
    	color: #fff !important;
    	background-color: $themeColor;
    	@include transition($t1);
    	@include border-radius(3px);
    	/*&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}*/
	}
}


.eventTitle{
	color: #333 !important;
	padding: 10px;
	text-transform: uppercase;
}
.toggleBtn{
	cursor: pointer;
	sup{
	display: none !important;
	}
}

.calendar-widget-table tbody .calendar-header td{
	
	    background-color: $themeColor;
}

.news-teaser{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;

	h3{
  		color: $themeColor;
  		font-size: 1.5em;
	}
	img{
		@include border-radius(50%);
	}
}



.noticesBG{
	position: fixed;
	display: none;
	top: 0px;
	left: 0px;
	z-index: 9999;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0, 0.75);
}
.noticesContainer{
	position: fixed;
	z-index: 9999;
	top: 50%;
	display: none;
	left: 50%;
	@include transform($center);
	padding: 30px;
	background-color: #dedede;
	max-width: 100%;
	width: 500px;
}
.NoticeTitle{
	text-transform: uppercase;
	font-weight: bold;
	padding: 0px !important;
    border-bottom: none !important;
    font-size: 1.3em;
}
.readMoreLinkNotices{
    font-size: 0.8em;
    border: 2px solid #333;
    padding: 3px 5px;
    margin-top: 10px;
    display: inline-block;
}
.fullWidth{
	position: relative;
	clear: both;
	width: 100%;
	float: left;
}
.closeNotices{
	position: absolute;
	top: 10px;
	right: 10px;
	padding-right: 20px;
	color: $themeColor;
	font-size: 0.8em;
	cursor: pointer;
	z-index: 9999; 

}
.closeNotices:after {
    content: "x";
    position: absolute;
    color: #d92d2a;
    display: block;
    right: 5px;
    top: 49%;
    text-transform: uppercase;
    @include transform(translateY(-50%));
}
.noticesInner{
	max-height: 90vh;
    overflow-y: auto;
    padding: 10px;
	*{
		color: #333;
	}
	.col-12{
		padding: 20px 0px;
		border-bottom: 2px solid #cccccc;
	}

}

.MainMenuClose{
	display: none !important;
}

.mainContent{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
	    max-width: 100%;
	 
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    max-width: 100%;
			background-color: #1b3080;
			&:hover{
		    	background-color: #5a5962 !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
.addToCalendar,
.attachment,
.readMoreLink,
.button{
		padding: 10px 20px !important;
	    display: inline-block;
	    text-align: center;
	    color: #fff;
	    font-size: 0.7em !important;
	    cursor: pointer;
	    *{
	    	font-size: 1em;
	    	color: #fff;

	    @include transition($t1);
	    }
	    border: none !important;
	    color: #fff !important;
	    font-weight: lighter; 
	    margin-top: 10px;
	    @include transition($t1);
	    @include border-radius(3px);
	   	background-color: $themeColor;
		&:hover{
	    	background-color: #4b4b4b !important;
	    }
}

/** Inputs  **/
#content{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    background-color: $themeColor;
			max-width: none !important;
		    width: auto !important;
		    display: inline-block;

			&:hover{
	    	    background-color: #71142b !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
/** Inputs  **/



.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.heading-2{
	color: $themeColor !important;font-weight: lighter;
	*{color: $themeColor !important;font-size: 1em;font-weight: lighter;}
}
.archives{
	position: relative;
	display: block;
	float: left;
	width: 100%;
	clear: both;
	padding: 0px !important;
	margin: 0px 0px 30px 0px !important;

	.archive{
		position: relative;
		display: block;
		float: left;
		clear: both;
		color: #fff;
		width: 100%;
		margin: 5px 0px !important;
		cursor: pointer;
		@include transition($t1);
		img{
			position: absolute;
		    right: 10px;
		    top: 50%;
		    max-width: 17px;
			@include transform(translateY(-50%));
		}
		a{
			display: block;
			width: 100%;
			clear: both;
			float: left;
			padding: 8px 30px  8px 15px;
			top: 0px;
			left: 0px;
			width: 100%;
			z-index: 1;
			height: 100%;
		}
		*{
		@include transition($t1);
			color: #fff !important;
		}
		
		@include border-radius(3px);

	    background-color: $themeColor !important;

		 &:hover{
	    	    background-color: #c2a544 !important;
	    		*{
	    			color: #fff !important;
	    		}
		    }
	}
}

.SubDate{
	margin-top: 20px;
    font-size: 1.4em;
    margin-bottom: 10px;
}


.archive{
	*{
		color: #fff !important;
	}
}
/*
|===============================================================
|	Uploads
|===============================================================
*/

.upload{
	position: relative;
	display: block;
	width: 100%;
	max-width: 500px;
	clear: both;
	float: left;
	padding: 10px 45px 12px 30px;
	border: 1px solid $themeColor;
	margin: 5px 0px;
	background-color: transparent;
	@include transition($t1);
	.uploadTitle{
		text-transform: uppercase;
		font-size: 1em;
		font-weight: normal !important;
		color: $themeColor !important;
	    display: inline-block;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.fileIcon{


		position: absolute;
		right: 10px;
		top: 50%;
		@include transform(translateY(-50%));



	    background-color: $themeColor;
	    display: inline-block;
	    float: right;
	    width: 25px;
	    height: 30px;
	    @include transition($t1);
	    span{
	    	@include transition($t1);
	    	color: #fff !important;
		    background-color: #000;
		    height: 13px;
		    width: 100%;
		    padding-top: 2px;
		    font-size: 7px;
		    text-transform: uppercase;
		    display: block;
		    text-align: center;
		    margin-top: 8px;
	    }
	}

	&:hover{
		background-color: #e2e0df !important;
		color: #1b3080 !important;
		.uploadTitle{
			/*color: #000 !important;*/
		}
		/*.fileIcon{
	    	background-color: #000 !important;
			span{
			    color: #000 !important;
				background-color: #009e7f;
		   }
		}*/
	}
}


.upload{
	/*padding: 10px;
	border-bottom: 2px solid #eee;*/
	*{
		vertical-align: middle;
		font-weight: lighter !important;
	}
}





.galleryImage{
	display: block;
	width: 33.33%;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}

/*
|===============================================================
|	Gallery 3
|===============================================================
*/

#bx_slider {
	height: 500px;
	li {
		height: 100%;
		text-align: center;
		img {
			display:inline-block;
			height: 100%;
		}
	}
}
.innerSlideGal{
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.GalNext,
.GalPrev{
	cursor: pointer;
	position: absolute;
	z-index: 200;
	top: 50%;
	margin-top: 8px;
	@include transform(translateY(-50%));
	i{
		color: #fff !important;
	}
}
.GalNext{
	left: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}
.GalPrev{
	right: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}

.bx_pages_frame {
	position: relative;
	#bx_pages {
		margin-top: 20px;
		li {
			background-position: center center;
			cursor: pointer;
			height: 200px;
			    opacity: 0.5;
			    @include transition(opacity 0.35s);
			    &:hover{
			   	 opacity: 1 !important;
			    }
		}
	}
	.controls {
		i {
			position: absolute;
			top: 50%;
			color: $mainColor2;
			font-size: 24px;
			margin-top: -12px;
			cursor: pointer;
			@include transition(all 0.5s);
			&:hover {
				opacity: 0.5;
			}
		}
		i.prev {
			left: 0;
		}
		i.next {
			right: 0;
		}
	}
}

/*
|===============================================================
|	Masonry
|===============================================================
*/

.grid {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 700px;
	a {
		font-size: 0;
		line-height: 0;
	}
}

.grid-item, .grid-sizer { 
	position: relative;
	width: 25%; 
	float: left;
	padding: 2px;
	cursor: pointer;
	@include transition(all 0.5s);
	img {
		width: 100%;
		height: auto;
	}
}

.grid-item.hoverState {
	opacity: 0.7;
}

.grid-item--width2 { width: 400px; }


.galleryImage{
	width: 33.33%;
    float: left;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}
#CustomLoginForm_LoginForm{
	max-width: 400px;
	width: 100%;
}

.staffProfile {
	.staffProfilePic {
		width:200px;
		height:200px;
		background-size: cover;
		background-position:center center;
		border-radius:20px;
	}
}
.HorizontalStack {
	.staffProfile {
		width:auto;
		margin:0 30px 30px 0;
		.profilePic {
			margin:0 !important;
		}
	}
}

/*
|===============================================================
|	Staff Page Templates
|===============================================================
*/

.StaffPage {
	.staffProfile {
		display:inline-block;
		width:100%;
		margin:30px;
		.profilePic {
			margin:0 30px 0 0;
			float:left;
			display:inline-block;
		}
		.bio {
			min-width:50%;

			p {
				display:block;
			}
		}
	}
}

.StaffPageStack {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.StaffPageNoPic {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.bio{
	h4{
		font-weight: normal;
    	font-size: 1.2em;
	}

	h3{
		text-transform: uppercase;
		font-weight: lighter;
	}
}

 .HorizontalStack .staffProfile {
    width: auto;
    margin: 0 30px 30px 0;
}

.VerticalStack{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.NameDepOnly{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.HorizontalStack{
	.profilePic{
		padding-bottom: 20px;
	}
}

.profilePic img{
	display: inline-block;
	padding-bottom: 20px;
}

.VerticalStack{
	.profilePic img{
		display: inline-block;
		padding-bottom: 0px;
	}
}


#SearchResults{ 
	padding: 0px;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
	font-size: 0.8em;
	li{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
		list-style: none !important;
	}
	.readMoreLink{
		font-size: 1em !important;
	}
}


.NewsletterItem{
	display: block;
	width: 20%;
	float: left;
	padding: 10px;
	.NewsletterInner{
		display: block;
		position: relative;
		width: 100%;
		@include border-radius(3px);
		@include transition($t1);
		border: 5px solid #fff;
		@include box-shadow(0px 0px 10px 0px #9c9c9c);
		overflow: hidden;
		.newsletterThumb{
			float: left;
			clear: both;
			background-color: #eee;
			width: 100%;
			background-image: url(../images/newsletter.png);
			background-size: 60%;
    		background-repeat: no-repeat;
			background-position: center;
			padding-bottom: 110%;
		}
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
	}
	&:hover{
		.NewsletterInner{
			border: 5px solid $themeColor !important;
		}
	}
	.newsletterTitle{
		padding: 10px;
		font-size: 0.8em;
		float: left;
	    width: 100%;
	    text-align: center;
	    clear: both;
	}
}

.Newsletter{
	width: 20%;
    padding-bottom:20%;
    position: relative;
	min-width: 200px;
    min-height: 200px;
    display: inline-block;
    float: none;
}
.NewsLetterInner{
	top: 50%;
	left: 50%;
	width: 90%;
	height: 90%;
	border-radius: 3px;
	overflow: hidden;
	position: absolute; 
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
}



.NewsLetterInner .imgContainer{
	display: inline-block;
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
.Newsletter a{
	position: absolute; 
	width: 100%;
	height: 100%;
	z-index: 200;
	left: 0px;
    top: 0px;
}
.Newsletter:hover .NewsLetterInner{
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-ms-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
}

.Newsletter .downloadInfo{
	position: absolute;
	transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	left: 50%;
	color: #777;
	width: 98%;
	text-align: center;
	font-size: 1em;
	bottom: 10%;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
}

.Newsletter:hover .downloadInfo{
	
    color: #e42c25 !important;
}

.NewsletterTitle{
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 30px;
	margin-top: 50px;
	text-align: center;
	font-size: 1.5em;
	color: #303c68 !important;
	*{
		font-weight: lighter;
		color: #303c68 !important;
	}
}

.InnerPage{
    .calPrev,
    .calNext {
        top: 37px;
    }
    .calendar-widget-table,
    .outerCalendarContainer{
        width: 100%;
    }
    .eventContainer{
        display: none !important;
    }
    .calHeader{
        .col-7,
        .col-5{
            width: 100%;
            text-align: center;
        }
        .col-5{
            display: none !important;
        }
    }
}
.group1{
	@include transition($t1);
	opacity: 0;
}
.PageLoaded{
	.group1{
		opacity: 1 !important;
	}
}
#gmap_canvas{
	min-height: 400px;
}


.SearchContainer{
    display: none;
    position: fixed;
    top: -4px;
    background-color: #dbb95a;
    padding: 7px;
    right: 0px;
    z-index: 9999;
	@include transform(translateY(100%));

	#Search{
		display: block;
		float: left;
	}

    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
	    background-color: #dbb95a;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;
	    @include border-radius(0px !important);
 

	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
    	@include border-radius(0px !important);
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 6px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: #dbb95a;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px 7px 10px;
	    color: #5f5e6d;
	    float: left;
    	@include border-radius(3px !important);
    	display: block;
    }
} 

.searchToggle{
	right: 69px;
	@include transition($t1);
}

.searchToggleButton{
	position: absolute;
	left: 50%;
	top: 7px;
	@include transform(translateX(-50%));
}

.searchToggle{
	position: absolute;
	z-index: 9999;
	top: 0px;
    cursor: pointer;
	right: 0px;
	height: 41px;
	width: 40px;
	text-align: center;
	padding-top: 8px;
	font-size: 1.1em;
	opacity: 1;
	*{
		color: #dbb95a;
	}
}

/** General Site layouts **/
















.CenterItems{
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex{
	display: flex;
}

.slink{
	cursor: pointer;
}


.translate{
	cursor: pointer;
}
.TranslateBG{
	display: none;
	position: fixed;
	top: 0px;
	z-index: 9999;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(41,37,89,0.76);
}
#google_translate_element{
	display: none;
	position: fixed; 
	z-index: 9999;
	top: 50%;
	left: 50%;
	@include transform($center);
}

#gmap_canvas{
	min-height: 500px;
}

.SearchContainer{
    display: none;
    position: fixed;
    top: -9px;
    background-color: #3d3a61;
    padding: 7px;
    left: 20px;
    z-index: 9999;
	@include transform(translateY(100%));

	#Search{
		display: block;
		float: left;
	}

    .middleColumn, 
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
	    background-color: #56b4b9;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;


	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 5px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: #56b4b9;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px;
	    color: #5f5e6d;
	    float: left;
    	display: block;
    }
}

/*** Scroll Bar ***/
::-webkit-scrollbar{
	width:8px;
	height:7px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
::-webkit-scrollbar-track-piece{
	background-color: #1c2023;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb{
	background-color: $themeColor;
	@include border-radius(0px); 
}
::-webkit-scrollbar-thumb:vertical{
}
::-webkit-scrollbar-thumb:horizontal{
}
::-webkit-scrollbar-thumb:hover{
	background-color: $themeColor;
}


.TopLevel::-webkit-scrollbar{
	width:4px;
	height:7px;
}
.TopLevel::-webkit-scrollbar-button:start:decrement,
.TopLevel::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
.TopLevel::-webkit-scrollbar-track-piece{
	background-color: #9c1816;
	@include border-radius(0px);
}
.TopLevel::-webkit-scrollbar-thumb{
	background-color: #ca1916;
	@include border-radius(0px); 
}
.TopLevel::-webkit-scrollbar-thumb:vertical{
}
.TopLevel::-webkit-scrollbar-thumb:horizontal{
}
.TopLevel::-webkit-scrollbar-thumb:hover{
	background-color: #ca1916;
}

.db{
	display: block;
}

fieldset{
	border: none;
}

/*** Search Form ***/


/*** HomePage ***/
.banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	height: 100vh;
	@include background-cover(url("../images/img5.jpg"));

	.darken{
		@include stretchCover(rgba(43,43,43, 0.5), absolute, 1)
	}

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		@include background-cover(url("../images/img5.jpg"));
	}
}

.bannerBg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	@include background-cover(url("../images/img5.jpg"));
}


#google_translate_element{
    border: 30px solid #312d5e;
}

.searchToggle{
	cursor: pointer;
	z-index: 8000;
	*{
		color: #fff;
	}
	position: fixed;
	top: 5px;
	left: 10px;
	span{
	display: inline-block;
	margin-left: 10px;
	}
}



/*** Page ***/
.Page{
	.banner{
		height: 50vh;
	}
}
/*** Footer ***/
footer{
	padding: 30px 0px 0px 0px;
}

/** General Site layouts **/
.quoteBox {

    *{
    	color: #bf2320 !important;
    }


    position: absolute;
    bottom: 230px;
    color: #bf2320;
    text-align: center;
    left: 50%;
   
    @include transform(translate(-50%,0%));
    z-index: 2;
    font-size: 2em;
    display: block;
    opacity: 1;
    line-height: 1.2em;

    a{
    	position: absolute;
    	top: 0px;
    	left: 0px;
    	width: 100%;
    	height: 100%;
    }

}

/*** HomePage ***/
.flexslider .flex-direction-nav {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 30px;  
    z-index: 800;
    width: calc(100% - 200px);
    @include transform($center);
}
.flexslider .slides li {
    position: relative;
    height: 100vh;
}
.banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	height: 100vh;
	//@include background-cover(url("../images/bg.jpg"));

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		//@include background-cover(url("../images/bg.jpg"));
	}
}

.darken{
	@include stretchCover(rgba(43,43,43, 0.2), absolute, 1)
}

#home1{
	/*z-index: 3;
	padding: 101px 0px 0px 0px;
	margin-top: -100px;*/
	z-index: 3;
    padding: 15px 0px 0px 0px;
    margin-top: -100px;
    margin-bottom: -14px;
	.inner{
		@include box-shadow(0px 0px 10px 0px rgba(0,0,0, 0.2));
	}
}

/*** Page ***/
.PageResults,
.InnerPage{
	.banner{
		height: 66vh;
	}
}

.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.CalendarFilter{
	padding: 10px;
}

/* .CalendarFilter{
	span{
		display: inline-block;
		padding: 5px 0px;
		margin: 0px 5px;
		cursor: pointer;
	}
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
	display: block;
} */
.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
	max-width: 500px;
	margin: 0px !important;
	padding: 0px !important;
}
#allEvents ul li,
#HomepageEvents ul li{
	margin-bottom: 10px;
	background-color: rgba(207, 205, 212, 0.81);
	float: left;
    width: 100%;
    clear: both;
    @include transition(background 0.35s);
    display: block;
    &:hover{
    	background-color: rgba(207, 205, 212, 1) !important;
    }
}
.calendar-widget-table{
	width: 100%;
}
.calendar-widget-table tbody td {
    background-color: transparent;
    padding: 20px 0px !important;
    @include transition($t1);
    text-align: center !important;
    width: 13%;
    font-size: 1em;
}



.calendar-widget-table .next,
.calendar-widget-table .prev{
    position: absolute;
  
    font-weight: lighter;
    font-size: 1.3em;
    width: 40px;
    height: 40px;
    background-color: $themeColor;
    text-align: center;
    border-radius: 50%;
    @include transition($t1);
}
.calendar-widget-table .prev{
    left: 0px;
    top: 3px;
    color: #fff !important;
}
.calendar-widget-table .next{
	right: 0px;
    top: 3px;
	color: #fff !important;
}
.calendar-widget-table .next,
.calendar-widget-table .prev{
	&:hover{
		background-color: #5a5962 !important;
		color: #fff !important;
	}
}
.show-month{
    font-weight: lighter;
    padding-bottom: 20px;
    display: block;
}

.calendar-widget {
    position: relative;
}

.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #333 !important;
	}
	.url{
		padding: 10px;
    	color: #fff !important;
    	background-color: $themeColor;
    	@include transition($t1);
    	@include border-radius(3px);
    	&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}
	}
}

.calendar-widget-table tbody .hasEvent{
	background-position: 4px 4px !important;
	background-image: url(../images/dot.png);
}
.calendar-widget-table table td, .calendar-widget table th{
	border: none !important;
}
.calday .day{
	font-style: italic;
	padding: 10px;
	display: inline-block;
	min-width: 40px;
    text-align: center;
	background-color: #f7c832;
	    background-color: $themeColor;
	    *{color: #fff !important;}
	    color: #fff !important;
}
.eventTitle{
	color: #333 !important;
	padding: 10px;
	text-transform: uppercase;
}
.toggleBtn{
	cursor: pointer;
	sup{
	display: none !important;
	}
}

.calendar-widget-table tbody .calendar-header td{
	
	    background-color: $themeColor;
}

.news-teaser{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;

	h3{
  		color: #303c68;
  		font-size: 1.5em;
	}
	img{
		@include border-radius(50%);
	}
}



.noticesBG{
	position: fixed;
	display: none;
	top: 0px;
	left: 0px;
	z-index: 9999;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0, 0.75);
}
.noticesContainer{
	position: fixed;
	z-index: 9999;
	top: 50%;
	display: none;
	left: 50%;
	@include transform($center);
	padding: 30px;
	background-color: #dedede;
	max-width: 100%;
	width: 500px;
}
.NoticeTitle{
	text-transform: uppercase;
	font-weight: bold;
	padding: 0px !important;
    border-bottom: none !important;
    font-size: 1.3em;
}
.readMoreLinkNotices{
    font-size: 0.8em;
    border: 2px solid #333;
    padding: 3px 5px;
    margin-top: 10px;
    display: inline-block;
}
.fullWidth{
	position: relative;
	clear: both;
	width: 100%;
	float: left;
}
.closeNotices{
	position: absolute;
	top: 10px;
	right: 10px;
	padding-right: 20px;
	color: #d92d2a;
	font-size: 0.8em;
	cursor: pointer;
	z-index: 9999; 

}
.closeNotices:after {
    content: "x";
    position: absolute;
    color: #d92d2a;
    display: block;
    right: 5px;
    top: 49%;
    text-transform: uppercase;
    @include transform(translateY(-50%));
}
.noticesInner{
	max-height: 90vh;
    overflow-y: auto;
    padding: 10px;
	*{
		color: #333;
	}
	.col-12{
		padding: 20px 0px;
		border-bottom: 2px solid #cccccc;
	}

}

.MainMenuClose{
	display: none !important;
}

.mainContent{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
	    max-width: 100%;
	 
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    max-width: 100%;
			background-color: #1b3080;
			&:hover{
		    	background-color: #5a5962 !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
.attachment,
.readMoreLink,
.button{
		padding: 10px 20px !important;
	    display: inline-block;
	    text-align: center;
	    color: #fff;
	    font-size: 0.7em !important;
	    cursor: pointer;
	    *{
	    	font-size: 1em;
	    	color: #fff;

	    @include transition($t1);
	    }
	    border: none !important;
	    color: #fff !important;
	    font-weight: lighter; 
	    margin-top: 10px;
	    @include transition($t1);
	    @include border-radius(3px);
	   	background-color: #1b3080;
		&:hover{
			color: $themeColor !important;
			*{
				color: $themeColor !important;
			}
	    	background-color: #4b4b4b !important;
	    }
}

.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.archives{
	position: relative;
	display: block;
	float: left;
	width: 100%;
	clear: both;
	padding: 0px !important;
	margin: 0px 0px 30px 0px !important;

	.archive{
		position: relative;
		display: block;
		float: left;
		clear: both;
		color: #fff;
		width: 100%;
		margin: 5px 0px;
		cursor: pointer;
		@include transition($t1);
		img{
			position: absolute;
		    right: 10px;
		    top: 50%;
		    max-width: 17px;
			@include transform(translateY(-50%));
		}
		a{
			display: block;
			width: 100%;
			clear: both;
			float: left;
			padding: 8px 30px  8px 15px;
			top: 0px;
			left: 0px;
			width: 100%;
			z-index: 1;
			height: 100%;
		}
		*{
		@include transition($t1);
			color: #fff !important;
		}
		
		@include border-radius(3px);

	   	background-color: #1b3080;

		 &:hover{
	    		background-color: #4b4b4b !important;
	    		
		    }
	}
}

.SubDate{
	margin-top: 20px;
    font-size: 1.4em;
    margin-bottom: 10px;
}


.archive{
	*{
		color: #fff !important;
	}
}
/*
|===============================================================
|	Uploads
|===============================================================
*/

.upload{
	position: relative;
	display: block;
	width: 100%;
	max-width: 500px;
	clear: both;
	float: left;
	padding: 10px 45px 12px 30px;
	border: 1px solid #1b3080;
	margin: 5px 0px;
	background-color: transparent;
	@include transition($t1);
	.uploadTitle{
		text-transform: uppercase;
		font-size: 1em;
		font-weight: normal !important;
		color: #1b3080 !important;
	    display: inline-block;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.fileIcon{


		position: absolute;
		right: 10px;
		top: 50%;
		@include transform(translateY(-50%));



	    background-color: #dbb95a;
	    display: inline-block;
	    float: right;
	    width: 25px;
	    height: 30px;
	    @include transition($t1);
	    span{
	    	@include transition($t1);
	    	color: #fff !important;
		    background-color: #1b3080;
		    height: 13px;
		    width: 100%;
		    padding-top: 2px;
		    font-size: 7px;
		    text-transform: uppercase;
		    display: block;
		    text-align: center;
		    margin-top: 8px;
	    }
	}

	&:hover{
		background-color: #e2e0df !important;
		color: #1b3080 !important;
		.uploadTitle{
			/*color: #000 !important;*/
		}
		/*.fileIcon{
	    	background-color: #000 !important;
			span{
			    color: #000 !important;
				background-color: #009e7f;
		   }
		}*/
	}
}


.upload{
	/*padding: 10px;
	border-bottom: 2px solid #eee;*/
	*{
		vertical-align: middle;
		font-weight: lighter !important;
	}
}





.galleryImage{
	display: block;
	width: 33.33%;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}

/*
|===============================================================
|	Gallery 3
|===============================================================
*/

#bx_slider {
	height: 500px;
	li {
		height: 100%;
		text-align: center;
		img {
			display:inline-block;
			height: 100%;
		}
	}
}

.calendar-widget-table{
	i{
		color: #fff !important;
	}
}
.innerSlideGal{
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.GalNext,
.GalPrev{
	cursor: pointer;
	position: absolute;
	z-index: 200;
	top: 50%;
	margin-top: 8px;
	@include transform(translateY(-50%));
	i{
		color: #fff !important;
	}
}
.GalNext{
	left: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}
.GalPrev{
	right: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}

.bx_pages_frame {
	position: relative;
	#bx_pages {
		margin-top: 20px;
		li {
			background-position: center center;
			cursor: pointer;
			height: 200px;
			    opacity: 0.5;
			    @include transition(opacity 0.35s);
			    &:hover{
			   	 opacity: 1 !important;
			    }
		}
	}
	.controls {
		i {
			position: absolute;
			top: 50%;
			color: $mainColor2;
			font-size: 24px;
			margin-top: -12px;
			cursor: pointer;
			@include transition(all 0.5s);
			&:hover {
				opacity: 0.5;
			}
		}
		i.prev {
			left: 0;
		}
		i.next {
			right: 0;
		}
	}
}

/*
|===============================================================
|	Masonry
|===============================================================
*/

.grid {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 700px;
	a {
		font-size: 0;
		line-height: 0;
	}
}

.grid-item, .grid-sizer { 
	position: relative;
	width: 25%; 
	float: left;
	padding: 2px;
	cursor: pointer;
	@include transition(all 0.5s);
	img {
		width: 100%;
		height: auto;
	}
}

.grid-item.hoverState {
	opacity: 0.7;
}

.grid-item--width2 { width: 400px; }


.galleryImage{
	display: inline-block;
	width: 33.33%;
	min-width: 180px;
	min-height: 180px;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}
#CustomLoginForm_LoginForm{
	max-width: 400px;
	width: 100%;
}

.staffProfile {
	.staffProfilePic {
		width:200px;
		height:200px;
		background-size: cover;
		background-position:center center;
		border-radius:20px;
	}
}
.HorizontalStack {
	.staffProfile {
		width:auto;
		margin:0 30px 30px 0;
		.profilePic {
			margin:0 !important;
		}
	}
}

/*
|===============================================================
|	Staff Page Templates
|===============================================================
*/

.StaffPage {
	.staffProfile {
		display:inline-block;
		width:100%;
		margin:30px;
		.profilePic {
			margin:0 30px 0 0;
			float:left;
			display:inline-block;
		}
		.bio {
			min-width:50%;

			p {
				display:block;
			}
		}
	}
}

.StaffPageStack {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.StaffPageNoPic {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.bio{
	h4{
		font-weight: normal;
    	font-size: 1.2em;
	}

	h3{
		text-transform: uppercase;
		font-weight: lighter;
	}
}

 .HorizontalStack .staffProfile {
    width: auto;
    margin: 0 30px 30px 0;
}

.VerticalStack{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.NameDepOnly{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.HorizontalStack{
	.profilePic{
		padding-bottom: 20px;
	}
}

.profilePic img{
	display: inline-block;
	padding-bottom: 20px;
}

.VerticalStack{
	.profilePic img{
		display: inline-block;
		padding-bottom: 0px;
	}
}


#SearchResults{ 
	padding: 0px;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
	font-size: 0.8em;
	li{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
		list-style: none !important;
	}
	.readMoreLink{
		font-size: 1em !important;
	}
}

.Newsletter{
	width: 25%;
    padding-bottom: 25%;
    position: relative;
	min-width: 200px;
    min-height: 200px;
    display: inline-block;
    float: none;
}
.NewsLetterInner{
	top: 50%;
	left: 50%;
	width: 90%;
	height: 90%;
	border-radius: 3px;
	overflow: hidden;
	position: absolute;
	border: 2px solid #e7e7e7;
	background-color: #efefef;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
}



.NewsLetterInner .imgContainer{
	display: inline-block;
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
.Newsletter a{
	position: absolute; 
	width: 100%;
	height: 100%;
	z-index: 200;
	left: 0px;
    top: 0px;
}
.Newsletter:hover .NewsLetterInner{
	background-color: #f5f5f5;
	border: 2px solid #e7e7e7;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-ms-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
}

.Newsletter .downloadInfo{
	position: absolute;
	transform: translateX(-50%); 
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	left: 50%;
	color: #777;
	width: 98%;
	text-align: center;
	font-size: 1em;
	bottom: 10%;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
}

.Newsletter:hover .downloadInfo{
	color: $themeColor !important;
}

.NewsletterTitle{
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 30px;
	margin-top: 50px;
	text-align: center;
	font-size: 1.5em;
	font-weight: lighter;
	color: $themeColor !important;
	font-weight: lighter;
	*{
		font-weight: lighter;
		color: $themeColor !important;
	}
}
.QuotesContainer{
	padding: 10%;
	background-color: #dbb95a;
	*{
		color: $themeColor;
	}
	.quote{
		font-size: 1.8em;
		font-style: italic;
	}
	.SliderName{
		display: inline-block;
		margin-top: 30px;
		padding-top: 20px;
		border-top: 1px solid $themeColor;
		text-transform: uppercase;
		font-size: 0.7em;
	}
}
.group1{
	@include transition($t1);
	opacity: 0;
}
.PageLoaded{
	.group1{
		opacity: 1 !important;
	}
}
#gmap_canvas{
	min-height: 400px;
}
.MainMenuClose{
	display: none !important;
}

/*** Menu ***/
#Menu{
	float: left;
    z-index: 9996;
    width: 100%;
  /*  padding-right: 30px;*/
    padding-right: 61px;
    /*margin-top: 15px;*/
    margin-top: 41px;
    text-align: right;
    @include transition($t1);
	display: inline-block;
	text-align: right;
	*{
		color: #333;
	}
	/*
	.MenuButton{
		display: inline-block;
		position: absolute;
		z-index: 2;
		cursor: pointer;
		background-color: #73a8d9;
	    right: 0px;
	    padding: 8px 12px 7px 12px;
	    cursor: pointer;
	    opacity: 1;
	    top: 0px;
	    text-transform: uppercase;
	}
	*/
	.MenuContainer{
		position: relative;
		.LevelOne{
			display: inline-block;
			text-align: center;
			position: relative;
			a{
				padding: 16px 25px;
			    text-transform: uppercase;
			   /* font-size: 1em;*/
			    font-size: 0.9em;
			    line-height: 3em;
			    color: #fff;
			    display: inline-block;
	   	 		line-height: 1.1em;
			}
		}
	}
	.SubMenu{
		@include box-shadow(-2px 13px 40px 0px rgba(47, 47, 47, 0.33));
		width: 250px;
		height: 0px;
		overflow: hidden;
		display: block;
		z-index: -9999;
    	left: 50%;
    	font-size: 0.8em;
    	text-align: left;
		position: absolute;
	    background-color: $themeColor;
	    border-radius: 0px;
	    margin-top: 0px;
	   /* padding: 3px 10px 6px 10px;*/
	    opacity: 0;
	    transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -o-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -ms-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -moz-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -webkit-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    @include transform(translateY(20px) translateX(-50%) scale(0.98));

	    a{
		    display: inline-block;
		    padding: 10px 20px !important;
		    /*font-size: 0.9em;*/
		    line-height: 1.2em !important;
		    width: 100%;

			background-color: transparent;
	    	@include transition($t1);
			&:hover{
    			background-color: rgba(255,255,255,0.2) !important;
			}
		}
	}
	.menuItem-1,
	.menuItem-2{
		.SubMenu{
			left: 0%;
			@include transform(translateY(20px) translateX(0%) scale(0.98));
		}
	}
	.menuItem-Last{
		.SubMenu{
			left: auto;
			right: 0%;
			@include transform(translateY(20px) translateX(0%) scale(0.98));
		}
	}
	.active .SubMenu{
		opacity: 1 !important;
	 	@include transform(translateY(0px) translateX(-50%) scale(1) !important);
	}
	.menuItem-1.active .SubMenu,
	.menuItem-2.active .SubMenu{
		opacity: 1 !important;
	 	@include transform(translateY(0px) translateX(0%) scale(1) !important);
	}
	.menuItem-Last.active .SubMenu{
			opacity: 1 !important;
			left: auto;
			right: 0%;
			@include transform(translateY(0px) translateX(0%) scale(1) !important);	
	}

	/*.SubMenu:before {
	    content: '';
	    display: block;
	    position: absolute;
	    width: 100%;
	    height: 17px;
	    left: 0px;
	    top: -14px;
	    z-index: 2;
	}
	.SubMenu:after {
	    content: '';
	    display: block;
	    position: absolute;
	    border: 10px solid transparent;
	    top: -20px;
	    left: 50%;
	    margin-left: -10px;
	    border-bottom: 10px solid #2f3b67;
	}*/
}


.topMenuLink{
	position: relative;
}

.SearchContainer{
    display: none;
    position: fixed;
    top: -4px;
    background-color: #dbb95a;
    padding: 7px;
    right: 0px;
    z-index: 9999;
	@include transform(translateY(100%));

	#Search{
		display: block;
		float: left;
	}

    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
	    background-color: #dbb95a;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;
	    @include border-radius(0px !important);
 

	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
    	@include border-radius(0px !important);
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 6px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: #dbb95a;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px 7px 10px;
	    color: #5f5e6d;
	    float: left;
    	@include border-radius(3px !important);
    	display: block;
    }
} 

.searchToggle{
	right: 69px;
	@include transition($t1);
}

.searchToggleButton{
	position: absolute;
	left: 50%;
	top: 7px;
	@include transform(translateX(-50%));
}

.searchToggle{
	position: absolute;
	z-index: 9999;
	top: 0px;
    cursor: pointer;
	right: 0px;
	height: 41px;
	width: 40px;
	text-align: center;
	padding-top: 8px;
	font-size: 1.1em;
	opacity: 1;
	*{
		color: #dbb95a;
	}
}
#header{
	top: 0px;
	left: 0px;
	width: 100%;
	z-index: 9990;
	min-height: 0px;
	position: fixed;
	background-color: transparent;
	@include transition($t1);
}
/** General Site layouts **/





.homebtn{
	position: absolute;
	left: 100px;
	top: 20px;
	z-index: 9998;
	@include transition($t1);
	img{
		@include transition($t1);
		max-width: 100%;
		width: 400px;
	}
}
.overlay{
	position: absolute;
	z-index: 2;
	top: 0px;    
	opacity: 0.8;
	width: 100%;
	left: 0px;
	height: 44vh;
	    height: 55vh;
}



#MenuIcon{
	display: none;
}

.bannerText{
	z-index: 90;
	position: absolute;
	top: 150px;
	font-weight: lighter;
	font-size: 3em;
	left: 100px;
	color: #fff;
	width: 40%;
}

.Grad{
    z-index: 1;
position: absolute;
bottom: 0px;
left: 0px;
width: 100%;
height: 55vh;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+76&0+0,1+77 */
background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.99) 76%, rgba(255,255,255,1) 77%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.99) 76%,rgba(255,255,255,1) 77%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.99) 76%,rgba(255,255,255,1) 77%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}


.homeImage{
	position: absolute;
	top: 50%;
	left: 50%;
	width: calc( 100% - 20px);
	height: calc( 100% - 20px);
	background-position: center;
	background-size: cover;
	//background-image: url(../images/bg.jpg);
	@include transform($center);
}

.homeTitle{
	margin-top: 30px;
	margin-bottom: 30px;
	.part1{
		color: $themeColor;
	}
	.part2{
		text-align: right;
		color: #2b2b2b;
	}
	.part1,
	.part2{
	font-weight: lighter;
		text-transform: uppercase;
		font-size: 4em;
	}
}
.btnLeft,
.btnRight{
	font-size: 1.2em;
	letter-spacing: 2px;
	text-align: center;
	color: #fff;
	text-transform: uppercase;
	padding: 15px 20px;
		@include transition($t1);
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	svg{
		position: absolute;
		top: 50%;
		@include transition($t1);
		@include transform(translateY(-50%)  scale(1.4));
	}
}
.btnLeft{
	svg{
		left: 15px;
		height: 90%;
		@include transform(translateY(-50%) rotate(-180deg)  scale(1.4));
	}
	background-color: $themeColor;
	&:hover{
		background-color: #414141;
		svg{
			left: 10px;
		}
	}
}
.btnRight{
		svg{
			right: 15px;
			height: 90%;
		}
		background-color: #414141;
	&:hover{
		background-color: $themeColor;
		svg{
			right: 10px;
		}
	}
}
.homeContent1{
	padding-right: 5%;
}

.btnContainer{
	position: absolute;	
	z-index: 9;
	@include transform(translateY(-100%));
	.col-6:first-of-type{
		padding-right: 3px;
	}
	.col-6:last-of-type{
		padding-left: 3px;
	}
}


.readMore{
	background-color: $themeColor;
	letter-spacing: 2px;
	text-align: center;
	display: inline-block;
	color: #fff;
	text-transform: uppercase;
	padding: 15px 40px;
	position: relative;
	padding-right: 50px;
	min-width: 40%;
	@include transition($t1);

	svg{
		position: absolute;
		top: 50%;
		right: 15px;
		@include transition($t1);
		@include transform(translateY(-50%)  scale(1.4));
	}
	&:hover{
		background-color: #414141;
		svg{
			right: 10px;
		}
	}
}

.homeContent{
	background-color: #fff;
	margin-top: -5px;
}

#home2{
	margin-top: -100px;
	min-height: 100vh;	
	padding-left: 33%;
	padding-right: 10%;
	z-index: 0;
	.next, 
	.prev{
		position: absolute;
		top: 59%;
		z-index: 100;
		@include transform(translateY(-50%));
	}
	.prev{
			left: 50px;
		i{    
			font-size: 4em;
			color: #fff;
		}
	}
	.next{
		right: 50px;
		i{    
			font-size: 4em;
			color: #fff;
		}
	}

	.part2{
		color: #000 !important;
	}
	.homeTitle{
		max-width: 500px;
		margin: 0 auto; 
		margin-bottom: 40px;
   	 	min-width: 0px;
   	 	margin-top: 258px;
   	 	padding-top: 50px;
	}
	section{
		font-size: 1.2em !important;
	}
}


#home3{
	background-color: #f4f5f5;
	    z-index: 3;
	padding: 100px 10% 0px 10%;
	.homeTitle{
		position: absolute;
		top: 40px;
		/*left: 50%;*/
		    left: 11%;
	    min-width: 0px;
	    max-width: 600px;
	    float: none;
	    margin: 0 auto;
	    padding-bottom: 60px !important;
	}
	.next,
	.prev{
		a{
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			position: absolute;
		}
		background-color: $themeColor;
		height: 50px;
		width: 50px;
		@include border-radius(50%);
		position: absolute;
		top: 98%;
		z-index: 100;
		@include transform(translateY(-50%));
		i{
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform($center);		}
	}
	.prev{
			left: -60px;
		i{    
			margin-left: -2px;
			font-size: 1.5em;
			color: #fff;
		}
	}
	.next{
		right: -60px;
		i{    
			margin-left: 2px;
			font-size: 1.5em;
			color: #fff;
		}
	}

	

}

	.topAngle2,
	.topAngle{
		bottom: 0px;
		@include transform(translateY(100%));
		position: absolute;
		width: 100%;
		height: 300px;
	}
.topAngle2{
		right: 0px;
		z-index: 1;
	}
	.topAngle{
		opacity: 0.7;
		left: 0px;
	}
.overlay2{
	    position: absolute;
    z-index: 80;
    top: -314px;
    width: 100%;
    left: 0px;
    height: calc(100% + 314px);
}
#TweetSliderContainer{
	padding-top: 60px;
	@include transform(translateY(36%));
    z-index: 9;
}

#SliderBg{
	position: absolute;
	top: 0px;
	left: 0px;
	background-attachment: fixed;
	-webkit-background-attachment: fixed;
	@include transition($t1);
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
}

.sliderInnerTweet{
	padding: 20px;
	color: #fff;
	background-color: $themeColor;
	i{
		font-size: 1.2em; 
	}
	*{
		color: #fff;
	}
}

#SuccessStories{

		*{font-size: 1em !important;}
}
#SuccessStoriesContainerOuter{
    /* position: absolute; */
    /* top: 64%; */
    position: relative;
    float: left;
    z-index: 880;
    width: 100%; 
    padding-bottom: 40px;
    /* right: 10%; */
    /* -webkit-transform: translateY(-50%); */

    /* transform: translateY(-50%); */

	*{
		color: #fff;
	}
}

#home4{
	z-index: 0;
	margin-top: -200px;
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg3.jpg);
	background-attachment: fixed;
	-webkit-background-attachment: fixed;
	height: 900px;
}

.MonthDropDownContainer{
	margin: 0 auto;
	width: 420px;
	height: 34px;
	position: relative;
	max-width: 100%;
}
.oh{
	overflow: hidden;
}
.MonthDropDown{
	margin-left: 140px;
	/*@include transition(margin-left 500ms);*/
    white-space: nowrap;
	.monthSelect:hover{
		background-color: #eee;
	}
	.monthSelect{
		text-transform: uppercase;
		@include transition(all 500ms);
		cursor: pointer;
		width: 140px;
    	text-align: center;
    	white-space: nowrap;
		letter-spacing: 2px;
		font-size: 0.8em;
		font-weight: 600;
		padding: 10px 20px;
		float: left;
		@include border-radius(3px);
		&.active{
			color: $themeColor !important;
		}
	}
}

.calNext,
.calPrev{
	cursor: pointer;
	background-color: $themeColor;
	height: 50px;
	width: 50px;
	@include border-radius(50%);
	position: absolute;
	top: 50%;
	z-index: 100;
	@include transform(translateY(-50%));
	i{
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform($center);
	}
}
.calPrev{
		left: -60px;
	i{    
		font-size: 1.5em;
		color: #fff;
	}
}
.calNext{
	right: -60px;
	i{    
		font-size: 1.5em;
		color: #fff;
	}
}

#EventNext,
#EventPrev{
	display: none;
}

.MonthDropDownIconContainer {
    float: left;
    margin-left: 64px;
}
.monthTitleContainer{
	display: none;
}

.MonthContainer{
    margin: 0 auto;
    clear: both;
    width: 550px;
    padding-top: 50px !important;
}

.noEventsInSlider{
	text-align: center;
	padding: 30px;
}


.calWidgetContainer{
	width: 550px;
	margin: 0 auto;
}

.CalEventItem{
	border-bottom: 1px solid $themeColor;
	padding: 20px;
	.url{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 1;
		display: block;
	}
}

.SliderDate{
	position: absolute;
	top: 50%;
	font-size: 2em;
	left: 15px;
	color: $themeColor;
	*{color: $themeColor;}
	@include transform(translateY(-50%));
	sup{
		font-size: 0.6em;
	}
}

.SliderContent{
	font-size: 0.8em;
	padding-bottom: 10px;
}

.SliderTitle{
	font-size: 1.2em;
	color: $themeColor;
	padding-bottom: 20px;
	position: relative;
	&:after{
		position: absolute;
		bottom: 10px;
		left: 0px;
		content: '';
		display: block;
		height: 2px;
		background-color: $themeColor;
		width: 20%;
	}
}
.SliderTime{
	font-size: 0.8em;
}
.SlideContentContainer{
	padding-left: 70px;	
}

.pageTitle{
	font-weight: lighter;
    text-transform: uppercase;
    font-size: 4em;
}



.calendarSection{
	padding-top: 100px;
	padding-bottom: 100px;
	.pageTitle {
	    font-weight: lighter;
	    text-transform: uppercase;
	    font-size: 4em;
	    margin: 0 auto;
	    float: none;
	    color: $themeColor;
	    max-width: 550px;
	    min-width: 0px;
	    padding-bottom: 40px;
	}
}

.NewsSection{
	background-color: #ededed;
	position: absolute;
	top: 50%;
	right: 0px;
	width: 50%;
	padding: 55px;
	min-height: 120%;
	@include transform(translateY(-50%));
	.pageTitle{
		margin-bottom: 30px;
		color: #000;
	}
}



.NewsItemInner{
	background-size: cover;
	background-position: center;
	background-image: url(../images/bg.jpg);
	padding-bottom: 100%;
	@include box-shadow(0px 0px 8px 0px rgba(0,0,0, 0.4));
}
.NewsItem-1{
	.NewsItemInner{
		padding-bottom: 50%;
	}
}

.NewsItem-2{
	padding: 20px 14px 40px 0px;
}
.NewsItem-3{
	padding: 20px 7px 40px 7px;
}
.NewsItem-4{
	padding: 20px 0px 40px 14px;
}





.NewsItemInner{
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.NewsToggle{
		position: absolute;
		bottom: 0px;
		height: 40px;
		min-width: 40px;
		width: 0%;
		overflow: hidden;
		@include transition($t1);
		background-color: $themeColor;
		span{
			white-space: nowrap;
			opacity: 0;
			display: inline-block;
			position: absolute;
			top: 50%;
			text-transform: uppercase;
			left: 15px;
			@include transform(translateY(-50%));
			@include transition($t1);
			color: #fff !important;
		}
	}
	.NewsToggleIcon{
		@include transition($t1);
		position: absolute;
		height: 36px;
		right: 2px;
		top: 50%;
		@include transform(translateY(-50%));
		width: 36px;
		&:after,
		&:before{
			@include transition($t1);
			content: '';
			width: 20px;
			top: 50%;
			left: 50%;
			height: 1px;
			background-color: #fff;
			position: absolute;
			display: block;
			transform-origin: 50% 50%;
			-o-transform-origin: 50% 50%;
			-ms-transform-origin: 50% 50%;
			-moz-transform-origin: 50% 50%;
			-webkit-transform-origin: 50% 50%;
		}
		&:after{
			@include transform($center rotate(-90deg));
		}
		&:before{
			@include transform($center rotate(0deg));
		}
	}

	&:hover{
		.NewsToggle{
			width: 100% !important;
			span{
				opacity: 1 !important;
			}
		}


		.NewsToggleIcon{
			background-color: #fff !important;
			&:after{
				@include transform($center rotate(-45deg));
			}
			&:before{
				@include transform($center rotate(45deg));
			}
			&:after,
			&:before{
				background-color: $themeColor !important;
				transform-origin: 100% 50%;
				-o-transform-origin: 100% 50%;
				-ms-transform-origin: 100% 50%;
				-moz-transform-origin: 100% 50%;
				-webkit-transform-origin: 100% 50%;
			}
		}
	}
}

#home5{
	/* margin-bottom: 300px;*/
	margin-bottom: 30px;
	min-height: 750px;
}

.fseFooter{
	text-align: center;
	font-size: 0.8em;
	padding: 20px;
	color: #fff;
	background-color: $themeColor;
	*{
		font-size: 1em;
		color: #fff;
	}
}

.footerIcon{
	background-color: $themeColor;
	height: 50px;
	width: 50px;
	display: inline-block;
	position: relative;
	overflow: hidden;
	margin: 10px;
	@include border-radius(50%);
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	img{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 25px;
		@include transform($center);
	}
}

.UsefulLinks{
	a{
		padding: 10px 20px;
		color: $themeColor;
		display: inline-block;
	}
}

.QuickLinks{
	z-index: 800;
	position: absolute;
	top: 50%;
	right: 0px;
	@include transform(translateY(-50%));


	.QuickLink{
		background-color: #000;
		border: 1px solid #fff;
		border-right: 0px !important;
		float: right;
		clear: both;
		position: relative;
		img{
			position: absolute;
			top: 50%;
			height: 30px;
			right: 10px;
			@include transform(translateY(-50%));
		}
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
		.linkText{
			text-transform: uppercase;
			white-space: nowrap;
			padding: 10px 0px;
			@include transition($t1);
			color: #fff;
			width: 0px;
			min-width: 0px;
			overflow: hidden;
			margin-right: 50px;
		}
		.active.linkText{
			padding: 10px !important;
			min-width: 190px !important;
		}
		&:hover{
			.linkText{
				padding: 10px !important;
				min-width: 190px !important;
			}
		}
	}
}

.whiteAngle{
    position: absolute;
    top: -205px;
    left: 0px;
    width: 100%;
    height: calc(36% + 205px);
	-webkit-clip-path: polygon(0 0, 100% 1%, 100% 100%, 0 53%);
	clip-path: polygon(0 0, 100% 1%, 100% 100%, 0 53%);
	background-color: #fff;
	display: none;
}

.slideBG{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
}


.ScrollNav{
	#header{
		min-height: 49px;
		background-color: $themeColor;
		@include box-shadow(0px 0px 20px 0px rgba(0,0,0,0.3));
	}
	.homebtn{
	left: 20px; 
		top: 7px;
		img{
			width: 150px;
		}
	}
	#Menu{
		margin-top: 0px;
	}

	#MenuIcon{
		top: -11px;
	}
}


#content{
	padding: 30px;
	    z-index: 1;
}

.InnerPage{

	background-color: #f4f5f5;
	#TweetSliderContainer {
		background-color: #f5f5f5;
		padding-top: 30px;
		padding-bottom: 30px;
		@include transform(translate(0px, 0px));
	}

	.homeTitle{
		max-width: 600px;
    	min-width: 0px;
	}
	.topAngle,
	.topAngle2{
		bottom: auto;
		top: 0px;
		@include transform(translateY(0px));
	}

	.readMore{
    	min-width: auto;
	}
}
.contentPage{
	padding-bottom: 500px;
}

.pageImageBottom{
	z-index: 0;
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	-webkit-background-attachment: fixed;
	height: 500px;
	background-image: url(../images/carbg.jpg);
}
footer{
	background-color: #fff;
}

.TweetsSlider{
	padding-left: 60px;
	padding-right: 60px;
	.next,
	.prev{
		a{
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			position: absolute;
		}
		background-color: $themeColor;
		height: 50px;
		width: 50px;
		@include border-radius(50%);
		position: absolute;
		top: 50%;
		z-index: 100;
		@include transform(translateY(-50%));
		i{
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform($center);		}
	}
	.prev{
			left: -50px;
		i{    
			font-size: 1.5em;
			color: #fff;
		}
	}
	.next{
		right: -50px;
		i{    
			font-size: 1.5em;
			color: #fff;
		}
	}
}


.typography{
	.readMore{
		color: #fff !important;
	}
}

.red1{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

.bannerTitle{
	position: absolute;
	top: 50%;
	left: 50%;
	color: #fff;
	@include transform($center);
	z-index: 4;
	font-size: 2.5em;
    font-weight: lighter;
}

.col-five{
	float: left;
}

.col-five.NewsItem{
	padding: 10px;
}

.bannerTitleNews{
	position: relative;
    float: right;
    padding: 200px 5% 5% 5%;
    color: #fff;
    width: 58%;
    z-index: 3;
	*{color: #fff;}
	.part1{
		color: #fff !important;
	}
}

.NewsHolder{
	.banner{
		height: auto;
		min-height: 66vh;
	}
}

.NewsHolder{ 
	#TweetSliderContainer {
	    @include transform(translateY(-50%));
	    background-color: transparent;
	}
	
	.TweetsSlider .next,
	.TweetsSlider .prev {
	    top: 0px;
	}
}

.TweetsSlider .next i{
		margin-left: 2px;
	}
	.TweetsSlider .prev i{

		margin-left: -2px;
	}
.newsTweets{
	margin-bottom: -6%;
}

.hideSideBarToggle{
    top: 27px !important;
    right: 18px !important;
}

.AlternateHeader{
	#MenuIcon span {
	    background-color: #000 !important;
	}
	.topMenuLink{
		color: #000 !important;
	}
}

.AlternateHeader.ScrollNav #header {
    min-height: 49px;
    background-color: #fff !important;
}

.SliderPage{
	.overlay {
	    height: 33vh;
	}
	#Menu {
    	padding-right: 61px;
	}
}


.leftSection{
	background-size: cover;
/*	background-attachment:fixed;
	-webkit-background-attachment:fixed;*/
	background-position: center;
	background-image: url(../images/bg.jpg);
}

/*.leftSection:nth-child(2){
	background-image: url(../images/507009337.jpg);
}*/

.sliderSectionInner{
    padding: 140px 10% 30px 10%;

    .part2{
        text-align: left;
    padding-left: 16%;
    }
}

.SliderSectionRow{
	padding: 20px 0px;
	border-bottom: 1px solid $themeColor;
}
.SliderSectionRowTitle{
	color: $themeColor;
	text-transform: uppercase;
}
.SliderSectionRowContent{

}


 
.SliderPage .homeTitle {
    font-size: 0.8em;
}

.SliderSubTitle{
	color: $themeColor;
	text-transform: uppercase;
	font-weight: lighter;
	font-size: 2em;
	border-bottom: 1px solid $themeColor;
	padding: 15px 0px;
	margin-bottom: 15px;
}

#SliderDown{
	cursor: default !important;
	position: fixed;
	bottom: 20px;
	left: 50%;
	display: block;
	width: 60px;
	height: 60px;
	@include border-radius(50%);
	background-color: $themeColor;
	@include transform(translateX(-50%));
	svg{
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform($center rotate(90deg));
	}
}




.newsRed{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: #d1350f;
	@include transition($t1);
	opacity: 0;

}
.newsTitle{
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform($center);
	width: 95%;
	text-align: center;
	color: #fff;
	@include transition($t1);
	opacity: 0;
}
.NewsItem:hover{
	.newsRed{
		opacity: 0.7;	
	}

	.newsTitle{
		opacity: 1;
	}
}

#EventSliderCalendar a{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}
.CalDay{
	color: #bf221f;
	font-size: 1.5em;
	position: absolute;
	top: 50%;
	left: 10px;
	@include transform(translateY(-50%));
	sup{
		color: #bf221f;
		font-size: 0.8em;
	}
	span {
		display: block;
		color: #bf221f;
		font-size: 0.9em;
	}
}
.CalContent{
	float: left;
	width: 100%;
	padding: 15px 20px 15px 85px;
	&:after{
		content: '';
		display: block;
		height: 2px;
		position: absolute;
		bottom: 0px;
		left: 0px;
		background-color: #bf2320;
		width: 100%;
	}
	i{
		color: #35485d;
	}
}

.EventTitle{
	font-size: 1.2em;
	position: relative;
	padding-bottom: 20px;
	color: #bf221f;
	&:after{
		content:'';
		display: block;
		position: absolute;
		height: 2px;
		width: 40px;
		bottom: 10px;
		left: 0px;
		background: #bf221f;
	}
}
.EventTime{
	color: #888;
	font-size: 0.8em;
	.fa{
		color: #888;
	}
}

#EventPrev2{
	float: left;
}
#EventNext2{
	float: right;
}
#EventPrev2,
#EventNext2{
	position: relative;
	display: inline-block;
	height: 40px;
	text-align: center;
	font-size: 1.4em;
	width: 40px;
	@include border-radius(50%);
	background-color: #bf221f;
	i{
		color: #fff;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}


.FooterContactDetails{
	padding: 20px;
	text-align: center;
}
.contentPage{
	.readMore{
		clear: both;
		display: block;
		margin-bottom: 15px;
		width: 100%;
		max-width: 500px;
	}
}

.MobileQuickLinks{
	display: none;
	background-color: rgba(0,0,0, 0.4);
	a{
		display: block;
		width: 100%;
		padding: 10px 20px;
		color: #fff;
		border-bottom: 1px solid rgba(255,255,255, 0.4);
	}
}

.policyBar{
	padding: 10px;
	font-size: 0.7em;
	background-color: #2b2b2b;
	color: #fff;
	*{
	color: #fff;
	}
}

.cookieWarning{
	width: 100% !important;
}

.pageList{
	a{
		display: block;
		margin-bottom: 5px;
		background-color: #eee;
		padding: 10px 20px;
		color: #000 !important;
		text-transform: uppercase;
		@include transition($t1);
		&:hover{
			background-color: #bf221f !important;
			color: #fff !important;
		} 
	}
}


.contentPage table{
	border-collapse: collapse;
    border: 1px solid #000;
    margin-bottom: 15px;
    max-width: 100% !important;
    width: 100% !important;
    }
.contentPage table th,
.contentPage table td{
padding: 10px 5px !important;
	border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid #000;
}

.contentPage table th{
	background-color:rgba(43,43,43 ,0.01);
} 

#MemberLoginForm_LoginForm{
	max-width: 500px; 
}

.error{
    display: inline-block;
    float: left;
    clear: both;
    padding: 10px 20px;
    margin-bottom: 20px;
    border: 1px solid #c70000;
    color: #c70000;
    background-color: #ffe7e7;
}
label {
    display: block;
    float: left;
    clear: both;
    width: 100%;
}
.calendar-widget-table{
	border: none !important;
	th{
		background-color: transparent !important;
	}
	*{ 
		border: none !important;
	}
}

.prev{
	i{
      margin-right: 2px;
	}
}
.next{
	i{
      margin-right: 2px;
	}
}
.calendar-widget-table .next{
	right: 5px;
}
.calendar-widget-table .prev{
	left: 5px;
	
}
.calendar-widget-table .next,
.calendar-widget-table .prev {
    width: 43px;
    height: 43px;
}

.userform-progress .step-buttons{
	margin-top: 10px;
	margin-bottom: 50px;
	    float: left;
    width: 100%;
    clear: both;
}
.userform-progress .step-button-wrapper{
	width: 40px;
    height: 40px;
}
.step-button-jump {
    border: none !important;
    width: 40px;
    height: 40px;
    color: #fff;
    background: #000;
}

.step-button-prev,
.step-button-next{
	border: none !important;
	margin: 20px 10px 10px 0px;
	padding: 10px 20px;
	background: #bf221f;
	color: #fff !important;
	coloe: #fff !important;
	cursor: pointer;
}
.step-buttons{
	text-align: center !important; 
}
.dropdown{
	margin-bottom: 10px;
}

.checkbox + label{
	display: inline;
	float: none !important;
}
fieldset{
	li{
		list-stye: none !important;
	}
}
.optionset{
	*{
		display: inline-block;

	}
}

.IE11{
	.NewsSection{
		min-height: 0px !important; 
	}
}



.up{
	cursor: pointer;
	height: 60px;
	width: 60px;
	position: relative;
	background-color: $themeColor;
	@include border-radius(50%);    
	margin-bottom: 20px;
    display: inline-block;
	*{
		color: #fff;
		font-size: 1.6em;
	}
	i{    margin-top: -3px;
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform($center);
	}
}

.BackToHome{
a{
	padding: 16px 0px !important;
}
		a i{
			color: #fff !important;
		}
	*{
		color: #fff !important;
	}
}

.NavSearch{
	padding: 16px 10px !important;
	i{
		color: #fff !important;
	}
	*{
		color: #fff !important;
	}
}

.blackout{
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 9998;
	background: rgba(0,0,0,0.75);
	display: none;
}

#SearchForm_SearchForm{
	position: fixed;
	top: 50%;
    left: 50%;
    width: 100%;
    max-width: 300px;
    z-index: 9999;
	transform: translateX(-49%) translateY(-50%);
	opacity: 0;
	display: none;
	transition: all .5s;
}

.active-search{
	opacity: 1 !important;
	display: block !important;
}

#SearchForm_SearchForm fieldset #SearchForm_SearchForm_Search {
    width: 300px;
    padding: 10px;
    border: 1px solid transparent;
    background-color: transparent;
    color: #FFFFFF;
    border: 0px;
    border-bottom: 4px solid #FFFFFF;
    z-index: 8888;
    font-size: 60px;
}

#SearchForm_SearchForm fieldset #SearchForm_SearchForm_action_results {
	position: absolute;
	top: -18%;
	right: -.5em;
    color: #ddd;
    transition: all 0.35s;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    outline: 0 !important;
    z-index: 9980;
    font-family: WebSymbols;
    text-decoration: none !important;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    border: none !important;
    margin-left: 20px;
    font-size: 8em;
    background-color: transparent;
    background-image: url(../images/searchIcon.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

#MobileSearch{
	position: fixed;
    width: 80px;
    height: 80px;
    z-index: 9997;
    transition: all .35s;
    right: 0px;
    cursor: pointer;
	overflow: hidden;
	color: white;
	font-size: 2em;
	margin-right: 1em;
	display: none;
}

.week-indicator{
	z-index: 9998;
    position: absolute;
    top: 3.5%;
    right: 7em;
    transform: translateY(-50%);
    font-weight: bold;
    color: white;
}